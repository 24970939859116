<template>
    <div class="wrapper">
        <van-nav-bar title="找回密码" @click-left="goHome">
            <template #left>
                <van-icon name="wap-home" size="18"/>
            </template>
            <template #right>
                <a @click="goHelpCenter">
                    <van-icon name="question-o" size="18"/>
                </a>
            </template>
        </van-nav-bar>
        <div class="reset_password">
            <div class="reset_password_form">
                <van-form>
                    <div class="reset_password_input">
                        <van-field name="手机号" v-model="forgetForm.phone" label="手机号" placeholder="请填写"
                                   :rules="[{ required: true, message: '请填写手机号' }]" required clearable/>
                        <van-field name="验证码" v-model.trim="forgetForm.authCode" label="验证码" placeholder="请填写"
                                   :rules="[{ required: true, message: '请填写验证码' }]" required clearable>
                            <template v-if="disabled" #button>
                                <van-button size="small" type="default">剩余 {{ remainingTime }} 秒获取验证码</van-button>
                            </template>
                            <template v-else #button>
                                <van-button size="small" type="default" @click="handleAuthCode">获取验证码
                                </van-button>
                            </template>
                        </van-field>

                        <van-field class="two_words" v-model="forgetForm.password" type="password" name="密码" label="密码"
                                   placeholder="请填写" :rules="[{required: true, message: '请输入密码', trigger: 'blur'}]"
                                   required clearable/>
                        <van-field name="确认密码" v-model="forgetForm.confirmPwd" type="password" label="确认密码" placeholder="请填写"
                                   :rules="[{required: true,message: '请确认密码', trigger: 'blur',}]" required clearable/>
                    </div>
                    <div class="submit_btn">
                        <van-button type="primary" native-type="submit" @click="handleSubmit" block>提交</van-button>
                    </div>
                    <div class="reset_password_btn">
                        密码已找回？<a class="inline_link" @click="goLogin">立即登录</a>
                    </div>
                </van-form>
            </div>
            <div class="footer">
                <div class="copyright">Copyright &copy; {{ (new Date).getFullYear() }} 节点速聘（西安）人力资源服务有限公司</div>
            </div>
        </div>
    </div>
</template>

<script>

import {reqForGetPWDApplicant, reqSendAuthCode} from "../../api/client-api";
import {encode64} from "../../assets/js/utils";

export default {
    data() {
        return {
            loading: false,
            disabled: false,
            remainingTime: 10,
            projectCode: null,
            forgetForm: {
                phone: '',
                authCode: '',
                password: '',
                confirmPwd: '',
                sign: false,
            },
        }
    },
    methods: {
        goLogin() {
            this.$router.push({name: 'login', query: {projectCode: this.projectCode}});
        },
        goHome() {
            this.$router.push({name: 'project', params: {id: this.projectCode}});
        },
        goHelpCenter() {
            this.$router.push({name: 'helpCenter', query: {projectCode: this.projectCode}});
        },
        handleAuthCode() {
            if (this.disabled) {
                return;
            }

            if (!this.forgetForm.phone) {
                this.$notify({type: 'danger', duration: '1000', message: '请输入手机号码'});
                return;
            }

            reqSendAuthCode({phone: this.forgetForm.phone}).then((res) => {
                this.remainingTime = 0;
                if (res.data.code === 1) {
                    this.remainingTime = res.data.data * 60;
                    this.disabled = true;
                    const run = setInterval(() => {
                        if (this.remainingTime === 0) {
                            clearInterval(run);
                            this.disabled = false;
                        }
                        this.remainingTime--;
                    }, 1000);
                }
            }).catch((err) => {
                this.remainingTime = 0;
                this.disabled = false;
                console.log('err===', err);
            })
        },
        //忘记密码
        handleSubmit() {
            this.loading = true;
            this.forgetForm.password = encode64(encode64(this.forgetForm.password) + 'pwd');
            this.forgetForm.confirmPwd = encode64(encode64(this.forgetForm.confirmPwd) + 'pwd');
            reqForGetPWDApplicant(this.forgetForm).then((res) => {
                this.loading = false;
                if (res.data.data === 1) {
                    this.$notify({type: 'success', duration: '1000', message: '密码已成功找回'});
                    this.$router.push({name: 'login', query: {projectCode: this.projectCode}});
                } else if (res.data.data === 2 || res.data.data === 4) {
                    this.$notify({type: 'danger', duration: '1000', message: '验证码不正确'});
                    this.forgetForm.password = ''
                    this.forgetForm.confirmPwd = ''
                } else if (res.data.data === 3) {
                    this.$notify({type: 'danger', duration: '1000', message: '用户未注册或已禁用'});
                    this.forgetForm.password = ''
                    this.forgetForm.confirmPwd = ''
                } else if (res.data.data === 5) {
                    this.$notify({type: 'danger', duration: '1000', message: '两次密码输入不一致'});
                    this.forgetForm.password = ''
                    this.forgetForm.confirmPwd = ''
                } else {
                    this.$notify({type: 'danger', duration: '1000', message: '修改失败'});
                    this.forgetForm.password = ''
                    this.forgetForm.confirmPwd = ''
                }
            })
        },
    },
    mounted() {
        this.projectCode = this.$route.query.projectCode;
    }
}
</script>

<style scoped>


</style>
