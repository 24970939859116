<template>
    <div class="wrapper">
        <div class="header">
            <Row>
                <Col span="18" class="title">
                    <Icon type="md-home"/>
                    V聘360
                </Col>
                <Col span="6" class="menu">
                    <a @click="handleLogin">登录</a> ｜ <a @click="handleRegister">注册</a> ｜ <a @click="goHelpCenter">帮助</a>
                </Col>
            </Row>
        </div>
        <div class="banner"><img src="../../assets/img/default-banner.png" alt=""></div>
        <div class="content">
            <div class="main-content">
                <div class="reg-steps">
                    <div class="headline">找回密码</div>
                    <div class="steps">
                        <Steps :current="currentStep">
                            <Step title="重置密码"></Step>
                            <Step title="修改成功"></Step>
                        </Steps>
                    </div>
                    <div class="step-1" v-if="currentStep === 0">
                        <Form ref="forgetPwdForm" :model="forgetPwdForm" :rules="forgetPwdFormRule" label-colon :label-width="90">
                            <FormItem label="手机号" prop="phone">
                                <Input size="large" type="text" placeholder="请输入" v-model.trim="forgetPwdForm.phone"/>
                            </FormItem>
                            <FormItem label="验证码" prop="authCode">
                                <Input size="large" type="text" placeholder="请输入" v-model.trim="forgetPwdForm.authCode">
                                    <Button type="primary" slot="append" v-if="disabled">剩余 {{ remainingTime }} 秒
                                    </Button>
                                    <Button type="primary" slot="append" v-else @click="handleAuthCode">获取验证码</Button>
                                </Input>
                            </FormItem>
                            <FormItem prop="password">
                                <div class="text-spacing" slot="label">密&#160;&#160;&#160;&#160;码:</div>
                                <Input size="large" type="password" placeholder="请输入密码"
                                       v-model.trim="forgetPwdForm.password"/>
                            </FormItem>
                            <FormItem prop="confirmPwd">
                                <div class="text-spacing" slot="label">确认新密码</div>
                                <Input type="password" size="large" placeholder="确认新密码" v-model.trim="forgetPwdForm.confirmPwd"/>
                            </FormItem>
                            <FormItem>
                                <div class="submit-btn">
                                    <Button size="large" type="primary" shape="circle" long @click="handleSubmit"
                                            :loading="loading">确认找回
                                    </Button>
                                </div>
                            </FormItem>
                        </Form>
                    </div>
                    <div class="step-2" v-if="currentStep === 1">
                        <div class="reg-result">
                            <div class="reg-success-icon"><i class="ivu-icon ivu-icon-ios-checkmark-circle"></i></div>
                            <div class="reg-success-text">恭喜您，修改成功！</div>
                        </div>
                        <div class="quick-link">
                            <a @click="handleLogin">立即登录</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
        <Login v-model="showLoginModal" :goHome="true" v-on:goProjectHome="goProjectHome"></Login>
    </div>
</template>

<script>
import {reqForGetPWDApplicant, reqSendAuthCode} from "../../api/client-api";
import {checkMobile, encode64} from "../../assets/js/utils";
import Footer from "../../components/pc/Footer";
import Login from "../../views/pc/login";

export default {
    components: {Footer, Login},
    data() {
        return {
            showLoginModal: false,
            currentStep: 0,
            loading: false,
            disabled: false,
            remainingTime: 10,
            forgetPwdForm: {
                phone: '',
                password: '',
                authCode: '',
            },
            forgetPwdFormRule: {
                password: [
                    {required: true, message: '请输入密码', trigger: 'blur'},
                    {
                        pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![!#$%^&*]+$)[\da-zA-Z!#$%^&*]{6,16}$/,
                        message: '密码由字母和数字组成6到16位',
                        trigger: 'blur'
                    },
                ],
                phone: [{
                    required: true, trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (!value) {
                            return callback(new Error('请输入手机号'));
                        } else if (!checkMobile(value)) {
                            return callback(new Error('手机号不正确'));
                        } else {
                            return callback();
                        }
                    }
                }],
                authCode: [{required: true, message: '请输入验证码', trigger: 'blur'}],
                confirmPwd: [{required: true, trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (!value) {
                            return callback(new Error('请再次输入新密码'));
                        }
                        let password = (!this.forgetPwdForm.password || this.forgetPwdForm.password == '') ? null : this.forgetPwdForm.password;
                        let confirmPwd = (!this.forgetPwdForm.confirmPwd || this.forgetPwdForm.confirmPwd == '') ? null : this.forgetPwdForm.confirmPwd;
                        if (password != null && password !== confirmPwd) {
                            callback(new Error('两次密码不一致！'));
                        } else {
                            callback();
                        }
                    }
                }]
            }
        }
    },
    methods: {
        goProjectHome() {
            this.$router.push({name: 'project', params: {id: this.projectCode}});
        },
        //忘记密码
        handleSubmit() {
            let that = this;
            that.$refs['forgetPwdForm'].validate((valid) => {
                if (!valid) {
                    return false;
                }

                that.loading = true;
                that.forgetPwdForm.password = encode64(encode64(that.forgetPwdForm.password) + 'pwd');
                that.forgetPwdForm.confirmPwd = encode64(encode64(that.forgetPwdForm.confirmPwd) + 'pwd');
                reqForGetPWDApplicant(that.forgetPwdForm).then((res) => {
                    that.loading = false;
                    if (res.data.data === 1) {
                        that.currentStep = 1;
                    } else if (res.data.data === 2 || res.data.data === 4) {
                        that.$Message.error('验证码不正确');
                        that.forgetPwdForm.password = ''
                        that.forgetPwdForm.confirmPwd= ''
                    } else if (res.data.data === 3) {
                        that.$Message.error('用户未注册或已禁用');
                        that.forgetPwdForm.password = ''
                        that.forgetPwdForm.confirmPwd= ''
                    } else if (res.data.data === 5) {
                        that.$Message.error('两次密码输入不一致');
                        that.forgetPwdForm.password = ''
                        that.forgetPwdForm.confirmPwd= ''
                    }else {
                        that.$Message.error('修改失败');
                        that.forgetPwdForm.password = ''
                        that.forgetPwdForm.confirmPwd= ''
                    }
                })
            });
        },
        closeModal(val) {
            this.$emit('input', val);
        },
        handleAuthCode() {
            if (this.disabled) {
                return;
            }

            if (!this.forgetPwdForm.phone) {
                this.$Message.error('请输入手机号码');
                return;
            }

            reqSendAuthCode({phone: this.forgetPwdForm.phone}).then((res) => {
                this.remainingTime = 0;
                if (res.data.code === 1) {
                    this.remainingTime = res.data.data * 60;
                    this.disabled = true;
                    const run = setInterval(() => {
                        if (this.remainingTime === 0) {
                            clearInterval(run);
                            this.disabled = false;
                        }
                        this.remainingTime--;
                    }, 1000);
                }
            }).catch((err) => {
                this.remainingTime = 0;
                this.disabled = false;
                console.log('err===', err);
            })
        },
        handleLogin() {
            this.showLoginModal = true;
        },
        goHelpCenter() {
            this.$router.push({name: 'helpCenter', query: {projectCode: this.projectCode}});
        },
        handleRegister() {
            this.$router.push({name: 'register', query: {projectCode: this.projectCode}});
        },
    },
    mounted() {
        this.projectCode = this.$route.query.projectCode;
    }
}
</script>
